/* -- Google fonts -- */
@import url('https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap');
/* -- website style -- */
a.external:link {
    text-decoration: none;
  }
  a.external:hover {
    text-decoration: underline;
  }
a.internal:link {
    padding-left: 2px;
    padding-right: 2px;
  }
  a.internal:hover {
    color: white;
    background-color: black;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 2px;
    padding-right: 2px;
    }
a.taglink:link {
    background-color: #f1f1f1 !important;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  }
  a.taglink:hover {
    color: white;
    background-color: black;
    }